import { useLocale as useAriaLocale } from 'react-aria'
import { Locale } from 'typ'

export const useLocale = () => {
  const { locale } = useAriaLocale()

  const normalizedLocale = locale === 'ja-JP' || locale === 'ja' ? 'ja' : 'en'

  return { locale: normalizedLocale as Locale }
}
