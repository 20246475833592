import React, { Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from 'react'

type MenuId = string | null
interface TopNavContextProps {
  openedMenuId: MenuId
  setOpenedMenuId: Dispatch<SetStateAction<MenuId>>
}

const TopNavContext = React.createContext<TopNavContextProps | null>(null)

export const TopNavContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openedMenuId, setOpenedMenuId] = useState<MenuId>(null)

  return (
    <TopNavContext.Provider
      value={{
        openedMenuId,
        setOpenedMenuId,
      }}
    >
      {children}
    </TopNavContext.Provider>
  )
}

export function useTopNavContext(): TopNavContextProps {
  const context = useContext(TopNavContext) || ({} as TopNavContextProps)
  if (context === undefined) {
    throw new Error('useTopNavContext must be used within TopNavContextProvider')
  }
  return context
}
