import { useState, MutableRefObject, useEffect } from 'react'
import { useDebounce } from 'use-debounce'

interface Dimensions {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
  x: number
  y: number
}

export const useResizeObserver = (
  ref: MutableRefObject<any> | any,
  deps: any[] = [],
  debounceMs = 0
): Dimensions | null | void => {
  const [dimensions, setDimensions] = useState<Dimensions | null>(null)
  const [debouncedDimensions] = useDebounce(dimensions, debounceMs)

  useEffect(() => {
    const observeTarget = ref?.current
    // eslint-disable-next-line react/display-name
    if (!observeTarget) return () => null
    const observer = new ResizeObserver((entries: any) =>
      entries.forEach((entry: any) => setDimensions(entry.contentRect))
    )

    observer.observe(observeTarget)
    return () => observer.unobserve(observeTarget)
    // eslint-disable-next-line
  }, [ref, setDimensions, ...deps])

  return debouncedDimensions
}
