import { FC, SVGAttributes } from 'react'
import { TestProps } from 'typ'
import { iconSprinkles } from 'vanilla-extract-config'
import { SvgIconProps } from '../types'
import { BaseIconWrapper } from './BaseIconWrapper'

export interface FillIconProps extends SvgIconProps, TestProps {
  d: string | string[]
  clipRule?: SVGAttributes<SVGPathElement>['clipRule']
  fillRule?: SVGAttributes<SVGPathElement>['fillRule']
}
export const FillIcon: FC<FillIconProps> = ({
  activeColor,
  className,
  clipRule = 'evenodd',
  color = 'inherit',
  d,
  fillRule = 'evenodd',
  size = 'md',
  ...props
}) => {
  const paths = Array.isArray(d) ? d : [d]
  return (
    <BaseIconWrapper
      {...props}
      className={className}
      activeColor={activeColor}
      color={color}
      size={size}
    >
      {paths.map((pathD, i) => (
        <path
          key={i}
          className={iconSprinkles({
            fill: 'inherit',
            stroke: 'transparent',
          })}
          d={pathD}
          clipRule={clipRule}
          fillRule={fillRule}
          stroke="transparent"
        />
      ))}
    </BaseIconWrapper>
  )
}
