import clsx from 'clsx'
import { FC, RefAttributes } from 'react'
import { Modal as AriaModal, ModalOverlayProps } from 'react-aria-components'
import { modalCx } from './Modal.css'
import { ModalOverlay } from './ModalOverlay'

export type ModalProps = ModalOverlayProps &
  RefAttributes<HTMLDivElement> & {
    overlayClassName?: string
    isEntering?: boolean
    isExiting?: boolean
  }
export const Modal: FC<ModalProps> = ({ children, overlayClassName, className, ...props }) => (
  // We want to stop propagation of events to underlying elements when modal is open
  <ModalOverlay className={overlayClassName} {...props}>
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
    <div tabIndex={-1} onKeyDown={(e) => e.stopPropagation()}>
      <AriaModal className={clsx([modalCx, className])} {...props}>
        {children}
      </AriaModal>
    </div>
  </ModalOverlay>
)
