/* eslint-disable sonarjs/cognitive-complexity */
import { AriaRole, PropsWithChildren, forwardRef, useId, useState } from 'react'
import {
  mergeProps,
  useButton,
  useFocus,
  useFocusVisible,
  useHover,
  useObjectRef,
} from 'react-aria'
import { ButtonProps as AriaButtonProps } from 'react-aria-components'
import { TestProps } from 'typ'
import { getComputedClassName } from './utils/get-computed-class-name'

export interface UnstyledButtonProps
  extends Omit<
      AriaButtonProps,
      | 'children'
      | 'form'
      | 'formAction'
      | 'formEncType'
      | 'formMethod'
      | 'formNoValidate'
      | 'className'
      | 'role'
    >,
    PropsWithChildren,
    TestProps {
  tabIndex?: number
  as?: React.ElementType
  role?: AriaRole
  className?:
    | string
    | ((
        state: Partial<{
          isFocused: boolean
          isHovered: boolean
          isPressed: boolean
          isFocusVisible: boolean
        }>
      ) => string)
}

export const UnstyledButton = forwardRef<HTMLElement, UnstyledButtonProps>(
  (
    {
      as: Component = 'button',
      children,
      className = '',
      isDisabled,
      onPress,
      role = 'button',
      ...props
    },
    forwardedRef
  ) => {
    const ref = useObjectRef(forwardedRef)
    const { isFocusVisible } = useFocusVisible()
    const [isFocused, onFocusChange] = useState(false)
    const { focusProps } = useFocus({ onFocusChange, isDisabled })
    const { hoverProps, isHovered } = useHover({ isDisabled })
    const { buttonProps, isPressed } = useButton({ ...props, onPress }, ref)
    const mergedProps = mergeProps(buttonProps, hoverProps, focusProps, props)
    const computedClassName = getComputedClassName(className, {
      isFocused,
      isHovered,
      isPressed,
      isFocusVisible,
    })
    const backupId = useId()
    const buttonId = mergedProps.id ?? backupId
    return (
      <Component
        {...mergedProps}
        id={buttonId}
        ref={ref}
        role={role}
        className={computedClassName}
        // We might want to add an aria-labelledby here but it is probably not necessary
        disabled={isDisabled}
      >
        {children}
      </Component>
    )
  }
)

UnstyledButton.displayName = 'Button'
