import { getTimezoneOffset } from 'date-fns-tz'

/**
 * Timezone offset to UTC
 *
 * @param tz
 * @param date This is the date to calculate the offset for. If not provided, the current date is used. This might be necessary to get the correct offset for daylight saving time.
 * @returns an object with the timezone offset to UTC in milliseconds and seconds
 */
export const getTzOffset = (tz?: string, date?: Date) => {
  const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const tzOffset = getTimezoneOffset(tz || currentTz, date)
  const tzOffsetSeconds = tzOffset / 1000
  return { ms: tzOffset, seconds: tzOffsetSeconds }
}
