import { FC } from 'react'
import { FillIcon } from './common/FillIcon'
import { SvgIconProps } from './types'

export const LinkedInIcon: FC<SvgIconProps> = ({ ...props }) => (
  <FillIcon
    {...props}
    data-testid="linked-in-icon"
    d={[
      'M14 1H1.99687C1.44687 1 1 1.45313 1 2.00938V13.9906C1 14.5469 1.44687 15 1.99687 15H14C14.55 15 15 14.5469 15 13.9906V2.00938C15 1.45313 14.55 1 14 1ZM5.23125 13H3.15625V6.31875H5.23438V13H5.23125ZM4.19375 5.40625C3.52812 5.40625 2.99063 4.86563 2.99063 4.20312C2.99063 3.54062 3.52812 3 4.19375 3C4.85625 3 5.39687 3.54062 5.39687 4.20312C5.39687 4.86875 4.85937 5.40625 4.19375 5.40625ZM13.0094 13H10.9344V9.75C10.9344 8.975 10.9187 7.97813 9.85625 7.97813C8.775 7.97813 8.60938 8.82188 8.60938 9.69375V13H6.53438V6.31875H8.525V7.23125H8.55312C8.83125 6.70625 9.50938 6.15312 10.5188 6.15312C12.6188 6.15312 13.0094 7.5375 13.0094 9.3375V13Z',
    ]}
  />
)
