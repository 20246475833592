import { FC } from 'react'
import { bottomLineCx, letterLeftEdgeCx, letterTopCx, twoTopLinesCx } from './PressIcon.css'
import { MenuIconProps } from './types'

export const PressIcon: FC<MenuIconProps> = ({ isActive }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 14.4992C2.99955 14.223 2.77533 13.9996 2.49919 14C2.22305 14.0004 1.99955 14.2247 2 14.5008L3 14.4992ZM2 14.5008L2.00653 18.5221L3.00652 18.5205L3 14.4992L2 14.5008ZM7.00751 18.5181V9H6.00751V18.5181H7.00751ZM4.50702 21.0186C5.888 21.0186 7.00751 19.899 7.00751 18.5181H6.00751C6.00751 19.3468 5.33571 20.0186 4.50702 20.0186V21.0186ZM2.00653 18.5221C2.00876 19.9015 3.12762 21.0186 4.50702 21.0186V20.0186C3.67927 20.0186 3.00787 19.3482 3.00652 18.5205L2.00653 18.5221Z"
      fill="url(#paint0_linear_1156_170)"
    />
    <path
      d="M4.46875 20.5186H20.5C22.1569 20.5186 23.5 19.1754 23.5 17.5186V9"
      stroke="url(#paint1_linear_1156_170)"
    />
    <g className={twoTopLinesCx({ isActive })}>
      <path d="M10.5 13.5H19.5" stroke="url(#paint2_linear_1156_170)" strokeLinecap="round" />
      <path d="M10.5 10.5H19.5" stroke="url(#paint3_linear_1156_170)" strokeLinecap="round" />
    </g>
    <path
      d="M10.5 16.5H19.5"
      stroke="url(#paint6_linear_1156_170)"
      strokeLinecap="round"
      className={bottomLineCx({ isActive })}
    />
    <path
      d="M6.5 12V6.5C6.5 5.94772 6.94772 5.5 7.5 5.5H22.5C23.0523 5.5 23.5 5.94772 23.5 6.5V12"
      stroke="url(#paint4_linear_1156_170)"
      strokeLinecap="round"
      className={letterTopCx({ isActive })}
    />
    <path
      d="M3 10.5C3 10.2239 2.77614 10 2.5 10C2.22386 10 2 10.2239 2 10.5H3ZM2 10.5V15.5H3V10.5H2Z"
      fill="url(#paint5_linear_1156_170)"
      className={letterLeftEdgeCx({ isActive })}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1156_170"
        x1="3.13534"
        y1="8.31233"
        x2="12.5706"
        y2="11.8662"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1156_170"
        x1="5"
        y1="6"
        x2="17.991"
        y2="24.8056"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1156_170"
        x1="11.9268"
        y1="13.4403"
        x2="12.179"
        y2="15.8977"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1156_170"
        x1="11.9268"
        y1="10.4403"
        x2="12.179"
        y2="12.8977"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1156_170"
        x1="9.19512"
        y1="5.11194"
        x2="14.2635"
        y2="19.463"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1156_170"
        x1="2.65854"
        y1="10.2015"
        x2="5.22656"
        y2="10.7575"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1156_170"
        x1="11.9268"
        y1="16.4403"
        x2="12.179"
        y2="18.8977"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
    </defs>
  </svg>
)
