import { FC } from 'react'
import { bottomElementCx, topElementCx } from './AboutUsIcon.css'
import { MenuIconProps } from './types'

export const AboutUsIcon: FC<MenuIconProps> = ({ isActive }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8362 5H5.85048C5.7586 5 5.67533 5.05818 5.63802 5.14847L2.95135 11.6485C2.88302 11.8138 2.99556 12 3.16381 12H20.1495C20.2414 12 20.3247 11.9418 20.362 11.8515L23.0487 5.35153C23.117 5.18621 23.0044 5 22.8362 5ZM5.85048 4C5.39107 4 4.97475 4.29092 4.78817 4.74233L2.1015 11.2423C1.75984 12.0689 2.32256 13 3.16381 13H20.1495C20.6089 13 21.0252 12.7091 21.2118 12.2577L23.8985 5.75767C24.2402 4.93106 23.6774 4 22.8362 4H5.85048Z"
      fill="url(#paint0_linear_3990_3059)"
      className={topElementCx({ isActive })}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0462 10H6.78048C6.6886 10 6.60533 10.0582 6.56802 10.1485L3.88135 16.6485C3.81302 16.8138 3.92556 17 4.09381 17H17.3595C17.4514 17 17.5347 16.9418 17.572 16.8515L20.2587 10.3515C20.327 10.1862 20.2144 10 20.0462 10ZM6.78048 9C6.32107 9 5.90475 9.29092 5.71817 9.74233L3.0315 16.2423C2.68984 17.0689 3.25256 18 4.09381 18H17.3595C17.8189 18 18.2352 17.7091 18.4218 17.2577L21.1085 10.7577C21.4502 9.93106 20.8874 9 20.0462 9H6.78048Z"
      fill="url(#paint1_linear_3990_3059)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3524 16H7.72189C7.62889 16 7.54483 16.0596 7.50819 16.1515L5.71462 20.6515C5.64887 20.8165 5.76141 21 5.92832 21H14.5588C14.6518 21 14.7359 20.9404 14.7725 20.8485L16.5661 16.3485C16.6318 16.1835 16.5193 16 16.3524 16ZM7.72189 15C7.25686 15 6.83657 15.298 6.65339 15.7576L4.85982 20.2576C4.53106 21.0824 5.09375 22 5.92832 22H14.5588C15.0238 22 15.4441 21.702 15.6273 21.2424L17.4209 16.7424C17.7496 15.9176 17.1869 15 16.3524 15H7.72189Z"
      fill="url(#paint2_linear_3990_3059)"
      className={bottomElementCx({ isActive })}
    />
    <defs>
      <linearGradient
        id="paint0_linear_3990_3059"
        x1="5.4878"
        y1="2.92537"
        x2="26.9883"
        y2="31.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3990_3059"
        x1="5.4878"
        y1="2.92537"
        x2="26.9883"
        y2="31.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3990_3059"
        x1="5.4878"
        y1="2.92537"
        x2="26.9883"
        y2="31.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
    </defs>
  </svg>
)
