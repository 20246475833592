import { FC } from 'react'
import { TestProps } from 'typ'
import { useLocale } from '../../hooks'
import { Color, Size, dotCx, indexKeys, wrapperCx } from './Loader.css'

export interface LoaderProps extends TestProps {
  color: Color
  size: Size
  label?: string
  isLoading?: boolean
}

const labelMap = {
  en: 'Loading',
  ja: '読み込み中',
}
export const Loader: FC<LoaderProps> = ({ size, color, label, isLoading = true, ...props }) => {
  const { locale } = useLocale()
  return (
    <div
      aria-busy={Boolean(isLoading)}
      aria-live="polite"
      aria-label={label ?? labelMap[locale]}
      role="status"
      aria-hidden={isLoading !== true}
      className={wrapperCx({ size })}
      {...props}
    >
      {indexKeys.map((index) => (
        <span
          key={index}
          className={dotCx({
            index,
            size,
            color,
          })}
        />
      ))}
    </div>
  )
}
