import { GenericDateTime } from 'typ'
import { isNil } from 'typeguards'
/**
 *
 * @param date A generic date time value
 * @returns A Date object
 */
export const dateFromGenericDateTime = (date: GenericDateTime) => {
  if (isNil(date)) return new Date(0)
  if (typeof date === 'object' && 'valueOf' in date) return new Date(date.valueOf())
  const res = new Date(date)
  return Number.isNaN(res.getTime()) ? new Date(0) : res
}
