/* eslint-disable import/no-duplicates */
import { isValid, parse } from 'date-fns'
import { ja } from 'date-fns/locale'
import { log } from 'logger'

export const isValidJpDateString = (date: string) => {
  try {
    const parsedDate = parse(date, 'P', new Date(), { locale: ja })
    return isValid(parsedDate)
  } catch (error) {
    log.error(error)
    return false
  }
}
