import { TopNavBar } from '@/components/navs/TopNavBar'
import { useViewport } from '@/contexts/ViewportContext'
import { contentCx, layoutCx } from '@/layouts/WebsiteLayout.css'
import { FC, PropsWithChildren } from 'react'
import { Footer } from './Footer'

export interface WebsiteLayoutProps extends PropsWithChildren {}
export const WebsiteLayout: FC<WebsiteLayoutProps> = ({ children }) => {
  const { width } = useViewport()
  return (
    <div className={layoutCx({ hasWidth: width > 0 })}>
      <TopNavBar />
      <main className={contentCx}>{children}</main>
      <Footer />
    </div>
  )
}
