import { getTimezoneOffset } from 'date-fns-tz'
import { toBrowserSafeUTCString } from './to-utc-string'

const JP_OFFSET = 32400000
/**
 * This function returns the result of adding the timezone offset between local time (override if applied) and Japan time (UTC+9)
 *
 * For example if the japanese time is 21:00, then this function will return 21:00 in local time, wherever the function is executed.
 *
 * **WARNING**: the japanese time can be expressed in any time format, i.e. 2021-09-07T15:00:00Z or 2021-09-08T00:00:00JST
 *
 * It is currently being used in places where we get a JST time from the API and need to display it in the user's local time.
 * For example in charts, where a JST time of 21:00 has to be expressed as the local time at 21:00 for the chart to display it correctly.
 *
 * @param time A time value expressed in the user's local time
 * @param timezoneOverride ONLY used for testing: it allows attempting to override the `time` value with user-specified offsets
 * @returns An ISO string in the format 2000-01-01T00:00:00Z
 */
export const offsetJstToLocalTimeString = (time: Date | string, timezoneOverride?: string) => {
  const currentTz = timezoneOverride ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  const offset = getTimezoneOffset(currentTz, new Date(time))
  return toBrowserSafeUTCString(new Date(new Date(time).getTime() - offset + JP_OFFSET))
}
