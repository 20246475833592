import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef, PropsWithChildren } from 'react'
import { TestProps } from 'typ'
import {
  AlignItems,
  Display,
  FlexDirection,
  FlexWrap,
  JustifyContent,
  PlaceItems,
  Spacing,
  sprinkles,
} from 'vanilla-extract-config'
import { BoxLayoutProps } from '../../types'
import { extractBoxLayoutProps } from '../../utils'

export type FlexOwnProps = {
  align?: AlignItems
  as?: React.ElementType
  columnGap?: Spacing
  direction?: FlexDirection
  display?: Extract<Display, 'flex' | 'none'>
  justify?: JustifyContent
  place?: PlaceItems
  rowGap?: Spacing
  flexWrap?: FlexWrap
  gap?: Spacing
}

export type FlexProps = PropsWithChildren &
  ComponentPropsWithoutRef<'div'> &
  TestProps &
  BoxLayoutProps &
  FlexOwnProps

export const Flex = forwardRef<HTMLDivElement, FlexProps>(
  (
    {
      align,
      as: Component,
      children,
      className,
      columnGap,
      direction: flexDirection,
      display = 'flex',
      justify,
      place,
      rowGap,
      flexWrap,
      gap,
      ...props
    },
    ref
  ) => {
    const Element = Component || 'div'

    const { layoutProps, testProps, colorProps, ...coreProps } = extractBoxLayoutProps(props)
    return (
      <Element
        {...testProps}
        {...coreProps}
        className={clsx([
          sprinkles({
            ...layoutProps,
            ...colorProps,
            align,
            columnGap,
            flexDirection,
            display,
            justify,
            place,
            rowGap,
            flexWrap,
            gap,
          }),
          className,
        ])}
        ref={ref}
      >
        {children}
      </Element>
    )
  }
)

Flex.displayName = 'Flex'
