import { FC } from 'react'
import { letterCx, longLowerBarCx, shortLowerBarCx, shortUpperBarCx } from './ArticlesIcon.css'
import { MenuIconProps } from './types'

export const ArticlesIcon: FC<MenuIconProps> = ({ isActive }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 10.5625C12.5 10.2864 12.7239 10.0625 13 10.0625H23.5625C23.8386 10.0625 24.0625 10.2864 24.0625 10.5625C24.0625 10.8386 23.8386 11.0625 23.5625 11.0625H13C12.7239 11.0625 12.5 10.8386 12.5 10.5625Z"
      fill="url(#paint0_linear_3955_2982)"
      className={shortUpperBarCx({ isActive })}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 13.8125C12.5 13.5364 12.7239 13.3125 13 13.3125H23.5625C23.8386 13.3125 24.0625 13.5364 24.0625 13.8125C24.0625 14.0886 23.8386 14.3125 23.5625 14.3125H13C12.7239 14.3125 12.5 14.0886 12.5 13.8125Z"
      fill="url(#paint1_linear_3955_2982)"
      className={shortLowerBarCx({ isActive })}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.625 17.0625C7.625 16.7864 7.84886 16.5625 8.125 16.5625H23.5625C23.8386 16.5625 24.0625 16.7864 24.0625 17.0625C24.0625 17.3386 23.8386 17.5625 23.5625 17.5625H8.125C7.84886 17.5625 7.625 17.3386 7.625 17.0625Z"
      fill="url(#paint2_linear_3955_2982)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.625 20.3125C7.625 20.0364 7.84886 19.8125 8.125 19.8125H23.5625C23.8386 19.8125 24.0625 20.0364 24.0625 20.3125C24.0625 20.5886 23.8386 20.8125 23.5625 20.8125H8.125C7.84886 20.8125 7.625 20.5886 7.625 20.3125Z"
      fill="url(#paint3_linear_3955_2982)"
      className={longLowerBarCx({ isActive })}
    />
    <g className={letterCx({ isActive })}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 5.6875C2.75 5.41136 2.97386 5.1875 3.25 5.1875H13C13.2761 5.1875 13.5 5.41136 13.5 5.6875V7.3125C13.5 7.58864 13.2761 7.8125 13 7.8125C12.7239 7.8125 12.5 7.58864 12.5 7.3125V6.1875H3.75V7.3125C3.75 7.58864 3.52614 7.8125 3.25 7.8125C2.97386 7.8125 2.75 7.58864 2.75 7.3125V5.6875Z"
        fill="url(#paint4_linear_3955_2982)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 13.8125C6 13.5364 6.22386 13.3125 6.5 13.3125H9.75C10.0261 13.3125 10.25 13.5364 10.25 13.8125C10.25 14.0886 10.0261 14.3125 9.75 14.3125H6.5C6.22386 14.3125 6 14.0886 6 13.8125Z"
        fill="url(#paint5_linear_3955_2982)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.125 5.1875C8.40114 5.1875 8.625 5.41136 8.625 5.6875V13.8125C8.625 14.0886 8.40114 14.3125 8.125 14.3125C7.84886 14.3125 7.625 14.0886 7.625 13.8125V5.6875C7.625 5.41136 7.84886 5.1875 8.125 5.1875Z"
        fill="url(#paint6_linear_3955_2982)"
      />
    </g>

    <defs>
      <linearGradient
        id="paint0_linear_3955_2982"
        x1="14.3331"
        y1="10.0028"
        x2="14.5302"
        y2="12.4703"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3955_2982"
        x1="14.3331"
        y1="13.2528"
        x2="14.5302"
        y2="15.7203"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3955_2982"
        x1="10.2309"
        y1="16.5028"
        x2="10.37"
        y2="18.9782"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3955_2982"
        x1="10.2309"
        y1="19.7528"
        x2="10.37"
        y2="22.2282"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3955_2982"
        x1="4.45427"
        y1="5.03078"
        x2="5.85335"
        y2="11.2337"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3955_2982"
        x1="6.67378"
        y1="13.2528"
        x2="7.18914"
        y2="15.624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3955_2982"
        x1="7.78354"
        y1="4.64272"
        x2="10.4346"
        y2="4.95726"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
    </defs>
  </svg>
)
