import { FC } from 'react'
import { logoOnBlack } from './Logos.css'
import { ResizableLogoProps } from './types'

export interface LogoShortOnBlackProps extends ResizableLogoProps {}

const DEFAULT_HEIGHT = 172
const DEFAULT_WIDTH = 773
export const LogoShortOnBlack: FC<LogoShortOnBlackProps> = ({
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="logo-short-on-black"
    aria-labelledby="Tensor Logo"
  >
    <title>Tensor Logo</title>
    <g transform={`scale(${width / DEFAULT_WIDTH})`}>
      <g className={logoOnBlack} opacity="0.25">
        <path
          d="M120.227 171.029H38.9426C34.6671 171.029 32.2529 168.741 33.5501 165.92L54.1604 121.092C55.4577 118.27 59.9753 115.983 64.2508 115.983H145.536C149.811 115.983 152.225 118.27 150.928 121.092L130.318 165.92C129.02 168.741 124.503 171.029 120.227 171.029Z"
          fill="#4082FF"
        />
      </g>
      <g className={logoOnBlack} opacity="0.5">
        <path
          d="M151.294 125.014H21.6252C17.3497 125.014 14.9355 122.727 16.2327 119.905L41.9047 64.0681C43.202 61.2465 47.7196 58.9591 51.9951 58.9591H181.664C185.939 58.9591 188.353 61.2465 187.056 64.0681L161.384 119.905C160.087 122.727 155.569 125.014 151.294 125.014H151.294Z"
          fill="#4082FF"
        />
      </g>
      <g className={logoOnBlack}>
        <path
          d="M184.296 77.0639H6.24313C1.96763 77.0639 -0.446572 74.7765 0.850628 71.9549L31.5843 5.109C32.8816 2.2874 37.3992 0 41.6747 0H219.727C224.003 0 226.417 2.2874 225.12 5.109L194.386 71.9549C193.089 74.7765 188.571 77.0639 184.296 77.0639H184.296Z"
          fill="#4082FF"
        />
      </g>
      <path
        d="M359.984 51.6346H328.333V146.256H308.116V51.6346H276.466V33.5721H359.984V51.6346Z"
        fill="white"
      />
      <path
        d="M379.541 111.789C379.541 125.709 385.175 132.172 397.935 132.172C408.374 132.172 414.009 127.697 414.837 120.24H433.894C432.733 138.633 419.974 148.244 397.935 148.244C372.746 148.244 359.655 135.65 359.655 111.623V94.7207C359.655 71.0234 372.581 58.2637 396.94 58.2637C421.3 58.2637 434.391 71.1895 434.391 94.8867V109.137H379.541V111.789ZM379.541 94.3886H415.5C415.5 80.4687 410.031 74.3378 397.604 74.3378C385.009 74.3378 379.541 80.4687 379.541 94.3886Z"
        fill="white"
      />
      <path
        d="M525.865 89.4179V146.256H505.979V91.2401C505.979 80.6346 502.003 75.83 493.883 75.83C485.598 75.83 479.301 80.6347 474.495 90.412V146.256H454.609V60.2519H471.512L472.175 72.0175C479.135 62.4062 487.586 58.2635 498.854 58.2635C516.751 58.2635 525.865 68.8691 525.865 89.4179Z"
        fill="white"
      />
      <path
        d="M542.603 120.406H561.659C561.659 128.857 566.962 133 578.728 133C589.168 133 594.471 129.519 594.471 122.891C594.471 115.766 589.168 112.617 574.585 110.961C552.711 108.309 544.426 101.35 544.426 85.4414C544.426 67.709 556.523 58.2637 578.563 58.2637C600.603 58.2637 612.201 67.543 612.201 85.2754H592.979C592.979 77.3203 588.339 73.5098 578.397 73.5098C568.951 73.5098 563.98 77.1543 563.98 83.2871C563.98 90.0801 568.951 92.7324 583.036 94.3887C605.407 96.875 614.024 104.662 614.024 121.068C614.024 139.131 602.093 148.244 578.563 148.244C554.203 148.244 542.603 138.965 542.603 120.406V120.406Z"
        fill="white"
      />
      <path
        d="M628.773 110.795V95.7146C628.773 70.8572 641.699 58.2634 666.887 58.2634C692.074 58.2634 705.166 70.8572 705.166 95.7146V110.795C705.166 135.65 692.075 148.244 666.887 148.244C641.698 148.244 628.773 135.65 628.773 110.795ZM685.281 110.297V96.2108C685.281 81.4628 679.48 74.8358 666.887 74.8358C654.293 74.8358 648.659 81.4628 648.659 96.2108V110.297C648.659 125.045 654.293 131.674 666.887 131.674C679.48 131.674 685.281 125.045 685.281 110.297Z"
        fill="white"
      />
      <path
        d="M772.612 58.5956V77.4862H768.139C754.882 77.4862 747.922 83.287 745.437 97.537V146.256H725.551V60.2519H742.453L743.282 75.9941C747.094 64.0644 754.385 58.5957 766.482 58.5957H772.613L772.612 58.5956Z"
        fill="white"
      />
    </g>
  </svg>
)
