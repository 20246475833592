import { FC, SVGAttributes } from 'react'
import { TestProps } from 'typ'
import { iconSprinkles } from 'vanilla-extract-config'
import { SvgIconProps } from '../types'
import { BaseIconWrapper } from './BaseIconWrapper'

export interface StrokeIconProps extends SvgIconProps, TestProps {
  d: string | string[]
  strokeWidth?: SVGAttributes<SVGPathElement>['strokeWidth']
  strokeLinecap?: SVGAttributes<SVGPathElement>['strokeLinecap']
  strokeLinejoin?: SVGAttributes<SVGPathElement>['strokeLinejoin']
}
export const StrokeIcon: FC<StrokeIconProps> = ({
  activeColor,
  className,
  color = 'inherit',
  d,
  size = 'md',
  strokeLinecap = 'round',
  strokeLinejoin = 'round',
  strokeWidth = 1,
  ...props
}) => {
  const paths = Array.isArray(d) ? d : [d]
  return (
    <BaseIconWrapper
      {...props}
      className={className}
      activeColor={activeColor}
      color={color}
      size={size}
    >
      {paths.map((pathD, i) => (
        <path
          key={i}
          className={iconSprinkles({
            stroke: 'inherit',
            fill: 'transparent',
          })}
          d={pathD}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        />
      ))}
    </BaseIconWrapper>
  )
}
