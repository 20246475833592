import { FC } from 'react'
import { tickCx } from './DevelopmentIcon.css'
import { MenuIconProps } from './types'

export const DevelopmentIcon: FC<MenuIconProps> = ({ isActive }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.875 4.5625C4.70241 4.5625 4.5625 4.70241 4.5625 4.875V21.125C4.5625 21.2976 4.70241 21.4375 4.875 21.4375H21.125C21.2976 21.4375 21.4375 21.2976 21.4375 21.125V4.875C21.4375 4.70241 21.2976 4.5625 21.125 4.5625H4.875ZM3.5625 4.875C3.5625 4.15013 4.15013 3.5625 4.875 3.5625H21.125C21.8499 3.5625 22.4375 4.15013 22.4375 4.875V21.125C22.4375 21.8499 21.8499 22.4375 21.125 22.4375H4.875C4.15013 22.4375 3.5625 21.8499 3.5625 21.125V4.875Z"
      fill="url(#paint0_linear_3981_3007)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.875 1.9375C18.1511 1.9375 18.375 2.16136 18.375 2.4375V5.6875C18.375 5.96364 18.1511 6.1875 17.875 6.1875C17.5989 6.1875 17.375 5.96364 17.375 5.6875V2.4375C17.375 2.16136 17.5989 1.9375 17.875 1.9375Z"
      fill="url(#paint1_linear_3981_3007)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.125 1.9375C8.40114 1.9375 8.625 2.16136 8.625 2.4375V5.6875C8.625 5.96364 8.40114 6.1875 8.125 6.1875C7.84886 6.1875 7.625 5.96364 7.625 5.6875V2.4375C7.625 2.16136 7.84886 1.9375 8.125 1.9375Z"
      fill="url(#paint2_linear_3981_3007)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5625 8.9375C3.5625 8.66136 3.78636 8.4375 4.0625 8.4375H21.9375C22.2136 8.4375 22.4375 8.66136 22.4375 8.9375C22.4375 9.21364 22.2136 9.4375 21.9375 9.4375H4.0625C3.78636 9.4375 3.5625 9.21364 3.5625 8.9375Z"
      fill="url(#paint3_linear_3981_3007)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0098 12.6464C17.2051 12.8417 17.2051 13.1583 17.0098 13.3536L12.1348 18.2286C11.9395 18.4238 11.623 18.4238 11.4277 18.2286L8.9902 15.7911C8.79493 15.5958 8.79493 15.2792 8.9902 15.0839C9.18546 14.8887 9.50204 14.8887 9.6973 15.0839L11.7812 17.1679L16.3027 12.6464C16.498 12.4512 16.8145 12.4512 17.0098 12.6464Z"
      fill="url(#paint4_linear_3981_3007)"
      className={tickCx({ isActive })}
    />
    <defs>
      <linearGradient
        id="paint0_linear_3981_3007"
        x1="6.55488"
        y1="2.43564"
        x2="29.9168"
        y2="27.7278"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3981_3007"
        x1="17.5335"
        y1="1.68377"
        x2="20.0581"
        y2="2.32687"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3981_3007"
        x1="7.78354"
        y1="1.68377"
        x2="10.3081"
        y2="2.32687"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3981_3007"
        x1="6.55488"
        y1="8.3778"
        x2="6.67611"
        y2="10.8551"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3981_3007"
        x1="10.1616"
        y1="12.1493"
        x2="16.8396"
        y2="22.3787"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
    </defs>
  </svg>
)
