import clsx from 'clsx'
import { ComponentPropsWithoutRef, PropsWithChildren, forwardRef, useId, useState } from 'react'
import { mergeProps, useFocus, useFocusVisible, useHover, useObjectRef, usePress } from 'react-aria'
import { TestProps } from 'typ'
import {
  Contrast,
  Variant,
  anchorCx,
  focusVisibleCx,
  hoveredCx,
  pressedCx,
  wrapperCx,
} from './Button.css'
import { ButtonIconProps, CoreTensorButtonProps } from './types'
import { getButtonIconSize } from './utils/get-button-icon-size'
import { getButtonLayout } from './utils/get-button-layout'
import { getComputedClassName } from './utils/get-computed-class-name'

export type CoreAnchorButtonProps = ComponentPropsWithoutRef<'a'> &
  PropsWithChildren &
  TestProps &
  CoreTensorButtonProps

export type DefaultAnchorButtonProps = CoreAnchorButtonProps &
  ButtonIconProps & {
    variant: Exclude<Variant, 'text' | 'solid'>
    contrast?: Contrast
  }

export type TextAnchorButtonProps = CoreAnchorButtonProps & {
  variant: 'text'
  contrast?: Contrast
  LeftIcon?: never
  RightIcon?: never
}

export type SolidAnchorButtonProps = CoreAnchorButtonProps &
  ButtonIconProps & {
    variant: 'solid'
    contrast?: never
  }

export type AnchorButtonProps =
  | DefaultAnchorButtonProps
  | TextAnchorButtonProps
  | SolidAnchorButtonProps

export const AnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  (
    {
      variant,
      size = 'md',
      children,
      className,
      LeftIcon,
      RightIcon,
      color,
      width = 'auto',
      contrast = 'default',
      target = '_blank',
      rel = 'noopener noreferrer',
      ...props
    },
    forwardedRef
  ) => {
    const ref = useObjectRef(forwardedRef)
    const { isFocusVisible } = useFocusVisible()
    const [isFocused, onFocusChange] = useState(false)
    const { focusProps } = useFocus({ onFocusChange })
    const { isPressed, pressProps } = usePress({})
    const { hoverProps, isHovered } = useHover({})
    const mergedProps = mergeProps(hoverProps, focusProps, pressProps, props)

    const backupId = useId()
    const buttonId = mergedProps.id ?? backupId

    const layout = getButtonLayout({ LeftIcon, RightIcon })
    const iconSize = getButtonIconSize(size)

    const computedClassName = getComputedClassName(className, {
      isFocused,
      isHovered,
      isPressed,
      isFocusVisible,
    })

    return (
      <a
        {...mergedProps}
        id={buttonId}
        ref={ref}
        className={clsx([
          wrapperCx({ size, color, variant, layout, contrast, width }),
          isHovered && hoveredCx({ variant, color, contrast }),
          isPressed && pressedCx({ variant, color }),
          isFocusVisible && isFocused && focusVisibleCx({ color }),
          anchorCx,
          computedClassName,
        ])}
        data-color={color}
        data-size={size}
        data-variant={variant}
        slot={undefined}
        target={target}
        rel={rel}
      >
        {
          <>
            {LeftIcon && <LeftIcon size={iconSize} />}

            {children}
            {RightIcon && <RightIcon size={iconSize} />}
          </>
        }
      </a>
    )
  }
)

AnchorButton.displayName = 'Button'
