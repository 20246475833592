import { FC } from 'react'
import { rectCx } from './CalculatorIcon.css'
import { MenuIconProps } from './types'

export const CalculatorIcon: FC<MenuIconProps> = ({ isActive }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="10"
      height="0"
      transform="matrix(1 0 0 -1 8 11)"
      fill="url(#paint0_linear_3957_2990)"
      className={rectCx({ isActive })}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.625 6.5C7.625 6.22386 7.84886 6 8.125 6H17.875C18.1511 6 18.375 6.22386 18.375 6.5V11.375C18.375 11.6511 18.1511 11.875 17.875 11.875H8.125C7.84886 11.875 7.625 11.6511 7.625 11.375V6.5ZM8.625 7V10.875H17.375V7H8.625Z"
      fill="url(#paint1_linear_3957_2990)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.625 4.0625C20.625 3.88991 20.4851 3.75 20.3125 3.75L5.6875 3.75C5.51491 3.75 5.375 3.88991 5.375 4.0625V21.9375C5.375 22.1101 5.51491 22.25 5.6875 22.25H20.3125C20.4851 22.25 20.625 22.1101 20.625 21.9375V4.0625ZM20.3125 2.75C21.0374 2.75 21.625 3.33763 21.625 4.0625V21.9375C21.625 22.6624 21.0374 23.25 20.3125 23.25H5.6875C4.96263 23.25 4.375 22.6624 4.375 21.9375V4.0625C4.375 3.33763 4.96263 2.75 5.6875 2.75L20.3125 2.75Z"
      fill="url(#paint2_linear_3957_2990)"
    />
    <path
      d="M8.9375 16.25C9.6106 16.25 10.1562 15.7043 10.1562 15.0312C10.1562 14.3582 9.6106 13.8125 8.9375 13.8125C8.2644 13.8125 7.71875 14.3582 7.71875 15.0312C7.71875 15.7043 8.2644 16.25 8.9375 16.25Z"
      fill="url(#paint3_linear_3957_2990)"
    />
    <path
      d="M13 16.25C13.6731 16.25 14.2188 15.7043 14.2188 15.0312C14.2188 14.3582 13.6731 13.8125 13 13.8125C12.3269 13.8125 11.7812 14.3582 11.7812 15.0312C11.7812 15.7043 12.3269 16.25 13 16.25Z"
      fill="url(#paint4_linear_3957_2990)"
    />
    <path
      d="M17.0625 16.25C17.7356 16.25 18.2812 15.7043 18.2812 15.0312C18.2812 14.3582 17.7356 13.8125 17.0625 13.8125C16.3894 13.8125 15.8438 14.3582 15.8438 15.0312C15.8438 15.7043 16.3894 16.25 17.0625 16.25Z"
      fill="url(#paint5_linear_3957_2990)"
    />
    <path
      d="M8.9375 20.3125C9.6106 20.3125 10.1562 19.7668 10.1562 19.0938C10.1562 18.4207 9.6106 17.875 8.9375 17.875C8.2644 17.875 7.71875 18.4207 7.71875 19.0938C7.71875 19.7668 8.2644 20.3125 8.9375 20.3125Z"
      fill="url(#paint6_linear_3957_2990)"
    />
    <path
      d="M13 20.3125C13.6731 20.3125 14.2188 19.7668 14.2188 19.0938C14.2188 18.4207 13.6731 17.875 13 17.875C12.3269 17.875 11.7812 18.4207 11.7812 19.0938C11.7812 19.7668 12.3269 20.3125 13 20.3125Z"
      fill="url(#paint7_linear_3957_2990)"
    />
    <path
      d="M17.0625 20.3125C17.7356 20.3125 18.2812 19.7668 18.2812 19.0938C18.2812 18.4207 17.7356 17.875 17.0625 17.875C16.3894 17.875 15.8438 18.4207 15.8438 19.0938C15.8438 19.7668 16.3894 20.3125 17.0625 20.3125Z"
      fill="url(#paint8_linear_3957_2990)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3957_2990"
        x1="1.58537"
        y1="-0.238806"
        x2="4.81452"
        y2="8.50108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3957_2990"
        x1="9.32927"
        y1="5.64925"
        x2="15.1983"
        y2="17.2756"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3957_2990"
        x1="22.6549"
        y1="6"
        x2="-4.04901"
        y2="26.7555"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3957_2990"
        x1="8.10518"
        y1="13.667"
        x2="11.1221"
        y2="16.9332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3957_2990"
        x1="12.1677"
        y1="13.667"
        x2="15.1846"
        y2="16.9332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3957_2990"
        x1="16.2302"
        y1="13.667"
        x2="19.2471"
        y2="16.9332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3957_2990"
        x1="8.10518"
        y1="17.7295"
        x2="11.1221"
        y2="20.9957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_3957_2990"
        x1="12.1677"
        y1="17.7295"
        x2="15.1846"
        y2="20.9957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_3957_2990"
        x1="16.2302"
        y1="17.7295"
        x2="19.2471"
        y2="20.9957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
    </defs>
  </svg>
)
