import { FC } from 'react'
import { leftColumnCx, middleColumnCx, rightColumnCx } from './AMIcon.css'
import { MenuIconProps } from './types'

export const AMIcon: FC<MenuIconProps> = ({ isActive }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g mask="url(#myMask)">
      <path
        d="M15.2363 4.46875L20.9238 4.46875V21.5312H15.2363L15.2363 4.46875Z"
        stroke="url(#paint0_linear_1147_272)"
        className={rightColumnCx({ isActive })}
      />

      <path
        d="M9.53125 4.65358C9.53125 4.56372 9.55718 4.5002 9.57831 4.46875H15.1717C15.1928 4.5002 15.2188 4.56372 15.2188 4.65358V21.3464C15.2188 21.4363 15.1928 21.4998 15.1717 21.5312H9.57831C9.55718 21.4998 9.53125 21.4363 9.53125 21.3464V4.65358Z"
        stroke="url(#paint1_linear_1147_272)"
        className={middleColumnCx({ isActive })}
      />
      <path
        d="M4.72566 4.496C4.7304 4.4857 4.7349 4.47665 4.73908 4.46875H9.38592C9.3901 4.47665 9.3946 4.4857 9.39934 4.496C9.45334 4.61332 9.5 4.81053 9.5 5.05522V20.9448C9.5 21.1895 9.45334 21.3867 9.39934 21.504C9.3946 21.5143 9.3901 21.5233 9.38592 21.5312H4.73908C4.7349 21.5233 4.7304 21.5143 4.72566 21.504C4.67166 21.3867 4.625 21.1895 4.625 20.9448V5.05522C4.625 4.81053 4.67166 4.61332 4.72566 4.496Z"
        stroke="url(#paint2_linear_1147_272)"
        className={leftColumnCx({ isActive })}
      />
    </g>
    <path d="M3 21.5312H23" stroke="url(#paint3_linear_1147_272)" strokeLinecap="round" />
    <mask id="myMask">
      <rect x="0" y="0" width="26" height="26" fill="white" />
      <rect x="0.59375" y="22.0312" width="24.2715" height="5" fill="black" />
    </mask>
    <defs>
      <linearGradient
        id="paint0_linear_1147_272"
        x1="15.7965"
        y1="2.89039"
        x2="31.2866"
        y2="9.0993"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1147_272"
        x1="10.0915"
        y1="2.89039"
        x2="25.5815"
        y2="9.0993"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1147_272"
        x1="5.0564"
        y1="2.89039"
        x2="19.1084"
        y2="7.83857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1147_272"
        x1="6.17073"
        y1="21.4715"
        x2="6.28517"
        y2="23.9495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
    </defs>
  </svg>
)
