import clsx from 'clsx'
import { ComponentPropsWithoutRef, FC } from 'react'
import { TestProps } from 'typ'
import { sprinkles } from 'vanilla-extract-config'
import { BoxLayoutProps } from '../../types'
import { extractBoxLayoutProps } from '../../utils'
import { ColorVariant, SizeVariant, dotCx } from './Dot.css'

export type DotProps = ComponentPropsWithoutRef<'div'> &
  TestProps &
  Omit<BoxLayoutProps, 'color' | 'bg'> & {
    color?: ColorVariant
    size?: SizeVariant
    hasOutline?: boolean
  }

export const Dot: FC<DotProps> = ({
  color = 'primary',
  size = 'md',
  hasOutline = false,
  className,
  ...props
}) => {
  const { layoutProps, testProps, colorProps, ...coreProps } = extractBoxLayoutProps(props)

  return (
    <div
      role="presentation"
      {...testProps}
      {...coreProps}
      data-color={color}
      data-size={size}
      data-has-outline={hasOutline}
      className={clsx([
        sprinkles({ ...layoutProps, ...colorProps }),
        dotCx({ size, color, hasOutline }),
        className,
      ])}
    />
  )
}
