import { useViewport } from '@/contexts/ViewportContext'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import { FC } from 'react'
import { FocusOn } from 'react-focus-on'
import { Box, CloseIcon, Grid, IconButton, Modal, ScrollArea } from 'ui-v2'
import { useExtractRawValues, vars } from 'vanilla-extract-config'
import { closeContainerCx, modalOverlayCx, paperCx, widgetContainerCx } from './DemoModal.css'

const InlineWidget = dynamic(() => import('react-calendly').then((mod) => mod.InlineWidget))

export interface DemoModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const CALENDLY_URL_EN = 'https://calendly.com/asako-saito-et-tensorenergy/30min'
const CALENDLY_URL_JP = 'https://calendly.com/asako-saito-et-tensorenergy/tensor-energy-30'

export const DemoModal: FC<DemoModalProps> = ({ isOpen, setIsOpen }) => {
  const closeModal = () => setIsOpen(false)
  const { lang } = useTranslation()
  const { width, height } = useViewport()
  const { backgroundColor, primaryColor, textColor } = useExtractRawValues({
    backgroundColor: vars.colorsBackground.canvas,
    textColor: vars.colorsText.neutral.default.highContrast,
    primaryColor: vars.colorsText.primary.default.highContrast,
  })
  const calendlyUrl = lang === 'en' ? CALENDLY_URL_EN : CALENDLY_URL_JP
  const styledCalendlyUrl = `${calendlyUrl}?primary_color=${primaryColor.replace('#', '')}&background_color=${backgroundColor.replace('#', '')}&text_color=${textColor.replace('#', '')}`
  // THESE BREAKPOINTS ARE BASED ON CALENDLY INTERNAL SETTINGS
  const isLg = width > 1110
  const isMd = width > 800
  const isSm = width > 600
  const isXs = width <= 400
  const isTall = height > 750
  const widgetWidth = isLg ? '1110px' : isMd ? '760px' : isSm ? '560px' : '100vw'
  const widgetHeight = isLg ? '700px' : isMd || isXs ? '1170px' : '1100px'

  return (
    <Modal isDismissable isOpen={isOpen} onOpenChange={setIsOpen} overlayClassName={modalOverlayCx}>
      <FocusOn preventScrollOnFocus>
        <Box
          height="100%"
          width="100%"
          position="relative"
          overflow="hidden"
          bg="baseColorsDanger2"
        >
          <div className={paperCx({ size: isTall ? 'tall' : 'default' })}>
            <ScrollArea>
              <Grid height="100%" width="100%" place="center">
                <div className={widgetContainerCx}>
                  <InlineWidget
                    url={styledCalendlyUrl}
                    styles={{
                      width: widgetWidth,
                      height: widgetHeight,
                      overflow: 'hidden',
                    }}
                  />
                </div>
              </Grid>
            </ScrollArea>
            <div className={closeContainerCx}>
              <IconButton
                size="md"
                shape="round"
                variant="ghost"
                color="neutral"
                onPress={closeModal}
                Icon={CloseIcon}
                label="Close"
              />
            </div>
          </div>
        </Box>
      </FocusOn>
    </Modal>
  )
}
