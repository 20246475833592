import { useDarkModeContext } from '@/contexts/DarkModeContext'
import Link from 'next/link'
import { FC } from 'react'
import { Flex, LogoLongOnBlack, LogoLongOnWhite, LogoShortOnBlack, LogoShortOnWhite } from 'ui-v2'
import {
  brandBlockContainerCx,
  brandBlockDesktopContainerCx,
  brandBlockTabletContainerCx,
} from './BrandBlock.css'

export interface BrandBlockProps {}

const DynamicLogoOnWhite = () => (
  <Flex align="center">
    <div className={brandBlockDesktopContainerCx}>
      <LogoLongOnWhite />
    </div>
    <div className={brandBlockTabletContainerCx}>
      <LogoShortOnWhite />
    </div>
  </Flex>
)

const DynamicLogoOnBlack = () => (
  <Flex align="center">
    <div className={brandBlockDesktopContainerCx}>
      <LogoLongOnBlack />
    </div>
    <div className={brandBlockTabletContainerCx}>
      <LogoShortOnBlack />
    </div>
  </Flex>
)

export const BrandBlock: FC<BrandBlockProps> = () => {
  const { isDarkModeEnabled } = useDarkModeContext()

  return (
    <Link
      aria-label="Home"
      href="/"
      passHref
      className={brandBlockContainerCx}
      data-testid="brand-block"
    >
      {isDarkModeEnabled ? <DynamicLogoOnBlack /> : <DynamicLogoOnWhite />}
    </Link>
  )
}
