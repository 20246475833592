import { FC } from 'react'
import { StrokeIcon } from './common/StrokeIcon'
import { SvgIconProps } from './types'

export const LinkIcon: FC<SvgIconProps> = ({ ...props }) => (
  <StrokeIcon
    {...props}
    data-testid="link-icon"
    strokeWidth={1.5}
    d={[
      'M8.5 1.5H2.5C1.94772 1.5 1.5 1.94772 1.5 2.5V13.5C1.5 14.0523 1.94772 14.5 2.5 14.5H13.5C14.0523 14.5 14.5 14.0523 14.5 13.5V7.5',
      'M7.5 8.5L14 2',
      'M14.5 4.5V2C14.5 1.72386 14.2761 1.5 14 1.5H11.5',
    ]}
  />
)
