import { FC } from 'react'
import { containerCx } from './ContactIcon.css'
import { MenuIconProps } from './types'

export const ContactIcon: FC<MenuIconProps> = ({ isActive }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={containerCx({ isActive })}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6036 9.39645C16.7988 9.59171 16.7988 9.90829 16.6036 10.1036L11.3223 15.3848C11.127 15.5801 10.8105 15.5801 10.6152 15.3848C10.4199 15.1895 10.4199 14.873 10.6152 14.6777L15.8964 9.39645C16.0917 9.20118 16.4083 9.20118 16.6036 9.39645Z"
      fill="url(#paint0_linear_3990_3147)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5807 2.80018C21.8052 2.73691 22.0425 2.73462 22.2682 2.79354C22.4939 2.85246 22.6998 2.97046 22.8648 3.1354C23.0297 3.30034 23.1477 3.50625 23.2066 3.73195C23.2656 3.95764 23.2633 4.19496 23.2 4.41948L23.1973 4.42898L17.2855 23.9238C17.2124 24.1789 17.0637 24.4059 16.8589 24.5747C16.6527 24.7447 16.3996 24.8478 16.1333 24.8701C15.867 24.8924 15.6003 24.8329 15.3687 24.6995C15.138 24.5666 14.9532 24.3667 14.8388 24.1264C14.8384 24.1255 14.8379 24.1246 14.8375 24.1237L10.5949 15.4053L1.87274 11.1614C1.63242 11.0469 1.43255 10.8621 1.29968 10.6314C1.16631 10.3999 1.10679 10.1331 1.12909 9.86685C1.15138 9.60057 1.25443 9.34743 1.42444 9.14128C1.59331 8.93652 1.82026 8.7878 2.07532 8.71468L21.5807 2.80018ZM21.8556 3.76169L2.35216 9.6757C2.29093 9.69298 2.23641 9.72845 2.19592 9.77753C2.15544 9.82662 2.13091 9.88689 2.1256 9.95029C2.12029 10.0137 2.13446 10.0772 2.16622 10.1323C2.19797 10.1875 2.2458 10.2316 2.30331 10.2588L2.30815 10.2611L11.1877 14.5816C11.2883 14.6305 11.3696 14.7118 11.4186 14.8124L15.7414 23.6959C15.7686 23.7534 15.8127 23.8012 15.8679 23.8329C15.923 23.8647 15.9865 23.8789 16.0499 23.8736C16.1133 23.8683 16.1736 23.8437 16.2227 23.8032C16.2717 23.7628 16.3073 23.7083 16.3246 23.647L16.3272 23.6377L22.2385 4.14461C22.2526 4.09223 22.2528 4.03706 22.2391 3.98454C22.225 3.9308 22.1969 3.88178 22.1577 3.84251C22.1184 3.80323 22.0694 3.77514 22.0156 3.76111C21.9631 3.7474 21.908 3.74761 21.8556 3.76169Z"
      fill="url(#paint1_linear_3990_3147)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3990_3147"
        x1="11.4646"
        y1="8.875"
        x2="19.239"
        y2="17.2918"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3990_3147"
        x1="4.63208"
        y1="1.43016"
        x2="32.0149"
        y2="31.0768"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
    </defs>
  </svg>
)
