import { Corner, Root, Scrollbar, Thumb, Viewport } from '@radix-ui/react-scroll-area'
import clsx from 'clsx'
import { CSSProperties, PropsWithChildren, UIEvent, forwardRef } from 'react'
import { TestProps } from 'typ'
import { rootCx, scrollbarCx, thumbCx, viewportCx } from './ScrollArea.css'

export interface ScrollAreaProps extends TestProps, PropsWithChildren {
  className?: string
  rootClassName?: string
  refTarget?: 'root' | 'viewport'
  style?: CSSProperties
  id?: string
  scrollMode?: 'vertical' | 'horizontal' | 'both'
  onScroll?: (event: UIEvent<HTMLDivElement>) => void
}

export const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(
  (
    {
      className,
      children,
      rootClassName,
      refTarget = 'root',
      id,
      scrollMode = 'both',
      onScroll,
      ...props
    },
    ref
  ) => (
    <Root
      className={clsx([rootCx, rootClassName])}
      {...props}
      ref={refTarget === 'root' ? ref : undefined}
      id={`${id}-root`}
    >
      <Viewport
        className={clsx([viewportCx, className])}
        ref={refTarget === 'viewport' ? ref : undefined}
        id={`${id}-viewport`}
        onScroll={onScroll}
      >
        {children}
      </Viewport>
      {scrollMode !== 'horizontal' && (
        <Scrollbar
          className={scrollbarCx({
            orientation: 'vertical',
          })}
          orientation="vertical"
        >
          <Thumb className={thumbCx} />
        </Scrollbar>
      )}
      {scrollMode !== 'vertical' && (
        <Scrollbar
          className={scrollbarCx({
            orientation: 'horizontal',
          })}
          orientation="horizontal"
        >
          <Thumb className={thumbCx} />
        </Scrollbar>
      )}
      <Corner />
    </Root>
  )
)

ScrollArea.displayName = 'ScrollArea'
