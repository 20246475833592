import { startOfDay } from 'date-fns'
import { offsetLocalTimeToJstString } from './offset-local-time-to-jst-string'
import { toBrowserSafeUTCString } from './to-utc-string'

/**
 *
 * @param time Time in local time
 * @param timezoneOverride Only used in tests to override the timezone
 * @returns Returns the local time offset to JST at 00:00:00 as ISO string
 */
export const offsetLocalTimeToJstZeroString = (time: string | Date, timezoneOverride?: string) => {
  const zeroTime = startOfDay(new Date(time))
  return toBrowserSafeUTCString(new Date(offsetLocalTimeToJstString(zeroTime, timezoneOverride)))
}
