import { LanguageSwitch } from '@/components/language-switch/LanguageSwitch'
import { useViewport } from '@/contexts/ViewportContext'
import { PROD_BASE_URL } from 'links'
import useTranslation from 'next-translate/useTranslation'
import { FC, PropsWithChildren, useState } from 'react'
import {
  BATTERY_STORAGE_OPTIMIZATION_URL,
  COMPANY_URL,
  CONTACT_URL,
  FEATURES_DEVELOPMENT_URL,
  FEATURES_MANAGEMENT_URL,
  FEATURES_OPERATIONS_URL,
  FIP_URL,
  INSIGHTS_URL,
  PRESS_URL,
  PRICING_URL,
} from 'src/assets/links'
import { AnchorButton, Box, Grid } from 'ui-v2'
import { DemoModal } from '../DemoModal'
import { DemoModalToggle } from '../DemoModalToggle'
import { BrandBlock } from './BrandBlock'
import { HamburgerMenuToggle } from './HamburgerMenuToggle'
import {
  buttonsSectionCx,
  containerCx,
  contentsBoxCx,
  languageSwitchContainerCx,
  linksSectionCx,
  navBoxCx,
} from './TopNavBar.css'
import { TopNavButton } from './TopNavButton'
import { TopNavContextProvider } from './context'
import { AMIcon } from './icons/AMIcon'
import { AboutUsIcon } from './icons/AboutUsIcon'
import { AggregationIcon } from './icons/AggregationIcon'
import { ArticlesIcon } from './icons/ArticlesIcon'
import { CalculatorIcon } from './icons/CalculatorIcon'
import { CareersIcon } from './icons/CareersIcon'
import { ContactIcon } from './icons/ContactIcon'
import { DevelopmentIcon } from './icons/DevelopmentIcon'
import { PressIcon } from './icons/PressIcon'

export type NavLinkProps = PropsWithChildren & {
  href: string
  activePath: string
}

export const d = {
  productTitle: 'tensor-website-common:nav.product.title',
  developmentTitle: 'tensor-website-common:nav.product.items.development.title',
  developmentSub: 'tensor-website-common:nav.product.items.development.sub',
  aggregationTitle: 'tensor-website-common:nav.product.items.aggregation.title',
  aggregationSub: 'tensor-website-common:nav.product.items.aggregation.sub',
  assetManagementTitle: 'tensor-website-common:nav.product.items.asset-management.title',
  assetManagementSub: 'tensor-website-common:nav.product.items.asset-management.sub',

  pvAndStorageTitle: 'tensor-website-common:nav.pv-storage.title',

  insightsTitle: 'tensor-website-common:nav.insights.title',
  fipCalculatorTitle: 'tensor-website-common:nav.insights.items.fip-calculator.title',
  fipCalculatorSub: 'tensor-website-common:nav.insights.items.fip-calculator.sub',
  articlesTitle: 'tensor-website-common:nav.insights.items.articles.title',
  articlesSub: 'tensor-website-common:nav.insights.items.articles.sub',

  companyTitle: 'tensor-website-common:nav.company.title',
  aboutUsTitle: 'tensor-website-common:nav.company.items.about-us.title',
  pressTitle: 'tensor-website-common:nav.company.items.press.title',
  careersTitle: 'tensor-website-common:nav.company.items.careers.title',
  contactUsTitle: 'tensor-website-common:nav.company.items.contact-us.title',

  pricingTitle: 'tensor-website-common:nav.pricing.title',

  getStarted: 'tensor-website-common:nav.get-started',
  login: 'tensor-website-common:nav.login',
}
export interface TopNavBarProps {}
export const TopNavBar: FC<TopNavBarProps> = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { width } = useViewport()
  const isDesktop = width > 1150

  return (
    <TopNavContextProvider>
      <Box className={containerCx}>
        <Box className={contentsBoxCx}>
          <Grid as="nav" className={navBoxCx}>
            <BrandBlock />
            <div className={linksSectionCx}>
              <TopNavButton
                key={d.productTitle}
                id={d.productTitle}
                label={t(d.productTitle)}
                menuItems={[
                  {
                    title: t(d.developmentTitle),
                    sub: t(d.developmentSub),
                    Icon: ({ isActive }) => <DevelopmentIcon isActive={isActive} />,
                    href: FEATURES_DEVELOPMENT_URL,
                    type: 'link',
                  },
                  {
                    title: t(d.aggregationTitle),
                    sub: t(d.aggregationSub),
                    Icon: ({ isActive }) => <AggregationIcon isActive={isActive} />,
                    href: FEATURES_OPERATIONS_URL,
                    type: 'link',
                  },
                  {
                    title: t(d.assetManagementTitle),
                    sub: t(d.assetManagementSub),
                    Icon: ({ isActive }) => <AMIcon isActive={isActive} />,
                    href: FEATURES_MANAGEMENT_URL,
                    type: 'link',
                  },
                ]}
              />
              <TopNavButton
                key={d.pvAndStorageTitle}
                id={d.pvAndStorageTitle}
                label={t(d.pvAndStorageTitle)}
                href={BATTERY_STORAGE_OPTIMIZATION_URL}
              />
              <TopNavButton
                key={d.insightsTitle}
                id={d.insightsTitle}
                label={t(d.insightsTitle)}
                menuItems={[
                  {
                    title: t(d.fipCalculatorTitle),
                    sub: t(d.fipCalculatorSub),
                    Icon: ({ isActive }) => <CalculatorIcon isActive={isActive} />,
                    href: FIP_URL,
                    type: 'link',
                  },
                  {
                    title: t(d.articlesTitle),
                    sub: t(d.articlesSub),
                    Icon: ({ isActive }) => <ArticlesIcon isActive={isActive} />,
                    href: INSIGHTS_URL,
                    type: 'link',
                  },
                ]}
              />
              <TopNavButton
                key={d.companyTitle}
                id={d.companyTitle}
                label={t(d.companyTitle)}
                menuItems={[
                  {
                    title: t(d.aboutUsTitle),
                    Icon: ({ isActive }) => <AboutUsIcon isActive={isActive} />,
                    href: COMPANY_URL,
                    type: 'link',
                  },
                  {
                    title: t(d.pressTitle),
                    Icon: ({ isActive }) => <PressIcon isActive={isActive} />,
                    href: PRESS_URL,
                    type: 'link',
                  },
                  {
                    title: t(d.careersTitle),
                    Icon: ({ isActive }) => <CareersIcon isActive={isActive} />,
                    href: 'https://www.linkedin.com/company/tensorenergy/jobs/',
                    type: 'anchor',
                  },
                  {
                    title: t(d.contactUsTitle),
                    Icon: ({ isActive }) => <ContactIcon isActive={isActive} />,
                    href: CONTACT_URL,
                    type: 'link',
                  },
                ]}
              />
              <TopNavButton
                key={d.pricingTitle}
                id={d.pricingTitle}
                label={t(d.pricingTitle)}
                href={PRICING_URL}
              />
            </div>
            <div className={buttonsSectionCx}>
              {isDesktop && (
                <AnchorButton
                  href={PROD_BASE_URL}
                  target="_blank"
                  rel="noreferrer"
                  data-testid="login-link"
                  variant="ghost"
                  color="primary"
                  size="lg"
                >
                  {t(d.login)}
                </AnchorButton>
              )}
              <div>
                <DemoModalToggle
                  openModal={() => setIsModalOpen(true)}
                  buttonText={t(d.getStarted)}
                  color="primary"
                />
                <DemoModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
              </div>
              <div className={languageSwitchContainerCx}>
                <LanguageSwitch />
              </div>
              {!isDesktop && <HamburgerMenuToggle />}
            </div>
          </Grid>
        </Box>
      </Box>
    </TopNavContextProvider>
  )
}
