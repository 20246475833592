import { FC } from 'react'
import { logoOnBlack } from './Logos.css'
import { ResizableLogoProps } from './types'

export interface LogoLongOnBlackProps extends ResizableLogoProps {}

const DEFAULT_HEIGHT = 60
const DEFAULT_WIDTH = 332
export const LogoLongOnBlack: FC<LogoLongOnBlackProps> = ({
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="logo-long-on-black"
    aria-labelledby="Tensor Logo"
  >
    <title>Tensor Logo</title>
    <g transform={`scale(${width / DEFAULT_WIDTH})`}>
      <g className={logoOnBlack} opacity="0.25">
        <path
          d="M32.354 53.25H12.9929C11.9746 53.25 11.3995 52.7202 11.7085 52.0666L16.6176 41.6834C16.9266 41.0298 18.0027 40.5 19.021 40.5H38.3821C39.4004 40.5 39.9755 41.0298 39.6665 41.6834L34.7574 52.0666C34.4484 52.7202 33.3723 53.25 32.354 53.25Z"
          fill="#4082FF"
        />
      </g>
      <g className={logoOnBlack} opacity="0.5">
        <path
          d="M40.0652 42.375H8.88116C7.85295 42.375 7.27236 41.8296 7.58432 41.1568L13.7582 27.8432C14.0702 27.1704 15.1566 26.625 16.1848 26.625H47.3688C48.397 26.625 48.9776 27.1704 48.6657 27.8432L42.4918 41.1568C42.1798 41.8296 41.0934 42.375 40.0652 42.375H40.0652Z"
          fill="#4082FF"
        />
      </g>
      <g className={logoOnBlack}>
        <path
          d="M47.8668 31.125H5.12853C4.10228 31.125 3.52279 30.5685 3.83416 29.882L11.2112 13.618C11.5226 12.9315 12.607 12.375 13.6332 12.375H56.3715C57.3977 12.375 57.9772 12.9315 57.6658 13.618L50.2888 29.882C49.9774 30.5685 48.893 31.125 47.8668 31.125H47.8668Z"
          fill="#4082FF"
        />
      </g>
      <path
        d="M103.095 19.1401H94.3423V45.3047H88.7519V19.1401H80V14.1455H103.095V19.1401Z"
        fill="white"
      />
      <path
        d="M107.949 35.7734C107.949 39.6225 109.507 41.4096 113.035 41.4096C115.922 41.4096 117.48 40.1723 117.709 38.1103H122.979C122.658 43.1967 119.13 45.8545 113.035 45.8545C106.07 45.8545 102.45 42.3721 102.45 35.7275V31.0537C102.45 24.5015 106.025 20.9732 112.761 20.9732C119.496 20.9732 123.116 24.5474 123.116 31.0996V35.0406H107.949V35.7734ZM107.949 30.9624H117.893C117.893 27.1133 116.38 25.418 112.944 25.418C109.461 25.418 107.949 27.1133 107.949 30.9624Z"
        fill="white"
      />
      <path
        d="M146.752 29.5874V45.3046H141.253V30.0918C141.253 27.1592 140.153 25.83 137.908 25.83C135.617 25.83 133.876 27.1592 132.547 29.8622V45.3046H127.048V21.5229H131.722L131.905 24.7764C133.83 22.1186 136.167 20.9731 139.283 20.9731C144.231 20.9731 146.752 23.9058 146.752 29.5874Z"
        fill="white"
      />
      <path
        d="M149.721 38.1562H154.99C154.99 40.4931 156.457 41.6386 159.71 41.6386C162.597 41.6386 164.063 40.6768 164.063 38.8438C164.063 36.8736 162.597 36.0024 158.565 35.5444C152.516 34.8115 150.225 32.8867 150.225 28.4878C150.225 23.585 153.57 20.9732 159.665 20.9732C165.759 20.9732 168.966 23.5391 168.966 28.4419H163.651C163.651 26.2427 162.368 25.1885 159.619 25.1885C157.007 25.1885 155.632 26.1968 155.632 27.8921C155.632 29.771 157.007 30.5039 160.902 30.9624C167.088 31.6494 169.47 33.8032 169.47 38.3399C169.47 43.334 166.171 45.8545 159.665 45.8545C152.928 45.8545 149.721 43.2885 149.721 38.1562Z"
        fill="white"
      />
      <path
        d="M172.996 35.4985V31.3286C172.996 24.4555 176.57 20.9731 183.535 20.9731C190.5 20.9731 194.12 24.4556 194.12 31.3286V35.4985C194.12 42.3721 190.5 45.8545 183.535 45.8545C176.57 45.8545 172.996 42.3721 172.996 35.4985ZM188.621 35.3613V31.4663C188.621 27.3882 187.017 25.5551 183.535 25.5551C180.052 25.5551 178.495 27.3882 178.495 31.4663V35.3613C178.495 39.4394 180.052 41.2725 183.535 41.2725C187.017 41.2725 188.621 39.4395 188.621 35.3613Z"
        fill="white"
      />
      <path
        d="M211.387 21.0644V26.2886H210.15C206.485 26.2886 204.56 27.8921 203.873 31.833V45.3047H198.374V21.5229H203.048L203.277 25.876C204.331 22.5766 206.347 21.0644 209.692 21.0644H211.387Z"
        fill="white"
      />
      <path
        d="M222.448 34.4907V27.251C222.448 18.774 226.251 14.7412 234.316 14.7412C241.969 14.7412 245.726 18.4073 245.955 26.0596H240.594C240.411 21.6148 238.532 19.6446 234.362 19.6446C229.872 19.6446 228.038 21.9356 228.038 27.3423V34.3989C228.038 39.8062 229.872 42.0972 234.362 42.0972C238.532 42.0972 240.411 40.127 240.594 35.7281H245.955C245.726 43.3345 241.969 47 234.316 47C226.251 47 222.448 42.9678 222.448 34.4907Z"
        fill="white"
      />
      <path d="M250.393 13H255.892V46.4502H250.393V13Z" fill="white" />
      <path
        d="M260.468 36.6446V32.4746C260.468 25.6011 264.042 22.1186 271.007 22.1186C277.972 22.1186 281.592 25.6011 281.592 32.4746V36.6446C281.592 43.5176 277.972 47 271.007 47C264.042 47 260.468 43.5175 260.468 36.6446ZM276.093 36.5068V32.6118C276.093 28.5337 274.489 26.7012 271.007 26.7012C267.525 26.7012 265.967 28.5337 265.967 32.6118V36.5068C265.967 40.5849 267.525 42.418 271.007 42.418C274.489 42.418 276.093 40.585 276.093 36.5068Z"
        fill="white"
      />
      <path
        d="M306.288 22.6685V46.4502H301.614L301.431 43.1055C299.46 45.8086 297.124 47 293.961 47C288.967 47 286.401 44.0674 286.401 38.3858V22.6685H291.9V37.8813C291.9 40.8599 293 42.1431 295.336 42.1431C297.627 42.1431 299.46 40.8599 300.789 38.019V22.6685H306.288Z"
        fill="white"
      />
      <path
        d="M331.528 13V46.4502H326.808L326.625 43.1968C324.746 45.8086 322.409 47 319.202 47C313.749 47 310.588 43.6553 310.588 37.1944V31.9708C310.588 25.5098 313.749 22.1187 319.202 22.1187C322.043 22.1187 324.242 23.0811 326.029 25.1431V13H331.528ZM326.029 37.9731V31.1455C324.746 28.2588 322.822 26.7012 320.256 26.7012C317.598 26.7012 316.086 28.3965 316.086 32.0161V37.1025C316.086 40.6768 317.598 42.418 320.302 42.418C322.822 42.418 324.746 40.8598 326.029 37.9731Z"
        fill="white"
      />
    </g>
  </svg>
)
