/* eslint-disable import/no-duplicates */
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale/en-US'
import { ja } from 'date-fns/locale/ja'
import { GenericDateTime } from 'typ'
import { dateFromGenericDateTime } from './date-from-generic-datetime'

export const getLocale = (locale: string) => (locale === 'ja' ? ja : enUS)

/**
 *
 * @param date A date to format into Calendar day format
 * @returns A formatted date string
 */
export const formatToCalendarDay = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'd', { locale: getLocale(locale) })
/**
 *
 * @param date A date to format into Calendar year format
 * @returns A formatted date string
 */
export const formatToCalendarYear = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'yyyy', { locale: getLocale(locale) })
/**
 *
 * @param date A date to format into Hours:Mins format
 * @returns A formatted date string
 */
export const formatToHMin = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'HH:mm', { locale: getLocale(locale) })
/**
 *
 * @param date A date to format into Hours:Mins:Seconds format
 * @returns A formatted date string
 */
export const formatToHMinSec = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'HH:mm:ss', { locale: getLocale(locale) })
/**
 *
 * @param date A date to format into Month/Day Hour:Minutes format
 * @returns A formatted date string
 */
export const formatToMDHMin = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'MM/dd HH:mm', {
    locale: getLocale(locale),
  })
/**
 *
 * @param date A date to format into Week Day Month Year format
 * @returns A formatted date string
 */
export const formatToWDMY = (date: GenericDateTime, locale: string = 'en') =>
  format(dateFromGenericDateTime(date), 'EEE do MMM, yyyy', {
    locale: getLocale(locale),
  })
/**
 *
 * @param date A date to format into Month Day, Year (EN) or Year年month月day日 (JA) format
 * @returns A formatted date string
 */
export const formatToLocalizedMDY = (date: GenericDateTime, locale: string) => {
  const formatString = locale === 'ja' ? 'yoMMMMdo' : 'MMMM d, yyyy'

  return format(dateFromGenericDateTime(date), formatString, {
    locale: getLocale(locale),
  })
}
/**
 *
 * @param date A date to format into Month Day format
 * @returns A formatted date string
 */
export const formatToMD = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'MM/dd', {
    locale: getLocale(locale),
  })
/**
 *
 * @param date A date to format into Year-Month-Day format
 * @returns A formatted date string
 */
export const formatToYMD = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'yyy/MM/dd', {
    locale: getLocale(locale),
  })

/**
 *
 * @param date A date to format into Year/Month/Day Hours:Mins format
 * @returns A formatted date string
 */
export const formatToYMDHMin = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'yyy/MM/dd HH:mm', {
    locale: getLocale(locale),
  })
/**
 *
 * @param date A date to format into Year/Month/Day Hours:Mins:Seconds format
 * @returns A formatted date string
 */
export const formatToYMDHMinSec = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'yyy/MM/dd HH:mm:ss', {
    locale: getLocale(locale),
  })
/**
 *
 * @param date A date to format into Month format
 * @returns A formatted date string
 */
export const formatToMonth = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'MMM', { locale: getLocale(locale) })

/**
 *
 * @param date A date to format into Jan 1 / １月１日 format
 * @returns A formatted date string
 */
export const formatToLocaleMonthDay = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), locale === 'ja' ? 'LLLdo' : 'LLL d', {
    locale: getLocale(locale),
  })
/**
 *
 * @param date A date to format into full Month format
 * @returns A formatted date string
 */
export const formatToFullMonth = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'MMMM', { locale: getLocale(locale) })

/**
 *
 * @param date A date to format into Month/Year format
 * @returns A formatted date string
 */
export const formatToYMonth = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'yyy/MM', {
    locale: getLocale(locale),
  })

/**
 *
 * @param date A date to format into Year format
 * @returns A formatted date string
 */
export const formatToY = (date: GenericDateTime, locale: string = 'en'): string =>
  format(dateFromGenericDateTime(date), 'yyy', { locale: getLocale(locale) })
