import { CoreTensorButtonProps } from '../types'

export const getComputedClassName = (
  className: CoreTensorButtonProps['className'],
  state: {
    isFocused: boolean
    isHovered: boolean
    isPressed: boolean
    isFocusVisible: boolean
  }
) => {
  const { isFocused, isHovered, isPressed, isFocusVisible } = state
  return typeof className === 'function'
    ? className({ isFocused, isHovered, isPressed, isFocusVisible })
    : className
}
