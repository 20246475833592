import { FC } from 'react'
import { logoOnWhite } from './Logos.css'
import { ResizableLogoProps } from './types'

export interface LogoShortOnWhiteProps extends ResizableLogoProps {}

const DEFAULT_HEIGHT = 172
const DEFAULT_WIDTH = 773

export const LogoShortOnWhite: FC<LogoShortOnWhiteProps> = ({
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="logo-short-on-white"
    aria-labelledby="Tensor Logo"
  >
    <title>Tensor Logo</title>
    <g transform={`scale(${width / DEFAULT_WIDTH})`}>
      <g className={logoOnWhite} opacity="0.25">
        <path
          d="M120.227 171.029H38.9425C34.667 171.029 32.2528 168.741 33.55 165.92L54.1603 121.092C55.4576 118.27 59.9752 115.983 64.2507 115.983H145.535C149.811 115.983 152.225 118.27 150.928 121.092L130.318 165.92C129.02 168.741 124.503 171.029 120.227 171.029Z"
          fill="#4082FF"
        />
      </g>
      <g className={logoOnWhite} opacity="0.5">
        <path
          d="M151.294 125.014H21.6251C17.3496 125.014 14.9354 122.727 16.2326 119.905L41.9046 64.0681C43.2019 61.2465 47.7195 58.9591 51.995 58.9591H181.664C185.939 58.9591 188.353 61.2465 187.056 64.0681L161.384 119.905C160.087 122.727 155.569 125.014 151.294 125.014H151.294Z"
          fill="#4082FF"
        />
      </g>
      <g className={logoOnWhite}>
        <path
          d="M184.296 77.0639H6.24313C1.96763 77.0639 -0.446572 74.7765 0.850628 71.9549L31.5843 5.109C32.8816 2.2874 37.3992 0 41.6747 0H219.727C224.003 0 226.417 2.2874 225.12 5.109L194.386 71.9549C193.089 74.7765 188.571 77.0639 184.296 77.0639H184.296Z"
          fill="#4082FF"
        />
      </g>
      <path
        d="M359.981 51.6324H328.33V146.254H308.113V51.6324H276.463V33.5699H359.981V51.6324Z"
        fill="#131523"
      />
      <path
        d="M379.538 111.785C379.538 125.705 385.172 132.168 397.932 132.168C408.371 132.168 414.006 127.693 414.834 120.236H433.891C432.73 138.63 419.971 148.242 397.932 148.242C372.743 148.242 359.652 135.648 359.652 111.619V94.7166C359.652 71.0213 372.578 58.2615 396.938 58.2615C421.297 58.2615 434.388 71.1873 434.388 94.8826V109.135H379.538V111.785V111.785ZM379.538 94.3864H415.497C415.497 80.4665 410.028 74.3356 397.601 74.3356C385.006 74.3356 379.538 80.4665 379.538 94.3864Z"
        fill="#131523"
      />
      <path
        d="M525.862 89.4137V146.253H505.977V91.2379C505.977 80.6324 502 75.8258 493.88 75.8258C485.595 75.8258 479.298 80.6324 474.492 90.4078V146.253H454.606V60.2497H471.509L472.172 72.0153C479.132 62.404 487.583 58.2614 498.852 58.2614C516.748 58.2614 525.862 68.8668 525.862 89.4137Z"
        fill="#131523"
      />
      <path
        d="M542.601 120.402H561.657C561.657 128.853 566.96 132.996 578.726 132.996C589.166 132.996 594.469 129.517 594.469 122.888C594.469 115.763 589.166 112.613 574.583 110.957C552.709 108.306 544.424 101.345 544.424 85.4373C544.424 67.7068 556.521 58.2615 578.561 58.2615C600.601 58.2615 612.199 67.5408 612.199 85.2713H592.977C592.977 77.3182 588.337 73.5057 578.395 73.5057C568.949 73.5057 563.978 77.1522 563.978 83.283C563.978 90.0779 568.949 92.7283 583.034 94.3865C605.405 96.8709 614.023 104.66 614.023 121.066C614.023 139.127 602.091 148.242 578.561 148.242C554.2 148.242 542.601 138.963 542.601 120.402V120.402Z"
        fill="#131523"
      />
      <path
        d="M628.771 110.791V95.7106C628.771 70.8551 641.697 58.2614 666.885 58.2614C692.072 58.2614 705.164 70.8552 705.164 95.7106V110.791C705.164 135.648 692.073 148.242 666.885 148.242C641.696 148.242 628.771 135.648 628.771 110.791ZM685.278 110.295V96.2087C685.278 81.4607 679.479 74.8316 666.885 74.8316C654.291 74.8316 648.657 81.4606 648.657 96.2087V110.295C648.657 125.043 654.291 131.672 666.885 131.672C679.478 131.672 685.278 125.043 685.278 110.295Z"
        fill="#131523"
      />
      <path
        d="M772.61 58.5914V77.484H768.137C754.88 77.484 747.92 83.2828 745.435 97.5348V146.254H725.549V60.2496H742.451L743.28 75.9918C747.092 64.0602 754.383 58.5914 766.48 58.5914H772.61H772.61Z"
        fill="#131523"
      />
    </g>
  </svg>
)
