import { FC } from 'react'
import { circleCx } from './CareersIcon.css'
import { MenuIconProps } from './types'

export const CareersIcon: FC<MenuIconProps> = ({ isActive }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1152_109)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.88223 5.1543C6.11888 5.1543 5.5 5.77317 5.5 6.53665C5.5 7.30013 6.11888 7.919 6.88223 7.919C7.64558 7.919 8.26445 7.30013 8.26445 6.53665C8.26445 5.77317 7.64558 5.1543 6.88223 5.1543ZM4.5 6.53665C4.5 5.22094 5.56653 4.1543 6.88223 4.1543C8.19793 4.1543 9.26445 5.22094 9.26445 6.53665C9.26445 7.85236 8.19793 8.919 6.88223 8.919C5.56653 8.919 4.5 7.85236 4.5 6.53665Z"
        fill="url(#paint0_linear_1152_109)"
        className={circleCx({ isActive })}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2319 10.8203C15.7201 10.8203 14.8171 11.3257 14.4562 11.6169L13.6422 12.2737V10.9548H10.8778V20.7111H13.6422V14.2222L13.7053 14.1088C13.9984 13.5818 14.6922 12.7783 15.753 12.7783C16.7862 12.7783 17.4039 13.3573 17.737 13.9612C18.0553 14.5382 18.1352 15.1633 18.1352 15.4296V20.8455H20.4963V15.4164C20.5181 14.5871 20.4488 13.4072 19.9917 12.4521C19.767 11.9827 19.4552 11.5813 19.0253 11.2955C18.5978 11.0113 18.0218 10.8203 17.2319 10.8203ZM14.6422 10.3441C15.2591 10.0587 16.1196 9.82031 17.2319 9.82031C18.191 9.82031 18.9657 10.0551 19.5789 10.4627C20.1899 10.8689 20.6089 11.4254 20.8937 12.0204C21.4541 13.1912 21.5188 14.562 21.4963 15.4361V21.8455H17.1352V15.4296C17.1352 15.2924 17.0808 14.842 16.8614 14.4442C16.6567 14.0732 16.3333 13.7783 15.753 13.7783C15.2764 13.7783 14.8777 14.1247 14.6422 14.4898V21.7111H9.87779V9.95477H14.6422V10.3441ZM4.5 9.95477H9.26445V21.7111H4.5V9.95477ZM5.5 10.9548V20.7111H8.26445V10.9548H5.5Z"
        fill="url(#paint1_linear_1152_109)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 1H3C1.89543 1 1 1.89543 1 3V23C1 24.1046 1.89543 25 3 25H23C24.1046 25 25 24.1046 25 23V3C25 1.89543 24.1046 1 23 1ZM3 0C1.34315 0 0 1.34315 0 3V23C0 24.6569 1.34315 26 3 26H23C24.6569 26 26 24.6569 26 23V3C26 1.34315 24.6569 0 23 0H3Z"
        fill="url(#paint2_linear_1152_109)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1152_109"
        x1="7.19512"
        y1="3.11654"
        x2="28.7423"
        y2="25.9308"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1152_109"
        x1="7.19512"
        y1="3.42563"
        x2="28.7423"
        y2="26.2399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1152_109"
        x1="4.12195"
        y1="-1.55224"
        x2="36.3027"
        y2="33.2873"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
    </defs>
  </svg>
)
