import { FC } from 'react'
import { containerCx } from './AggregationIcon.css'
import { MenuIconProps } from './types'

export const AggregationIcon: FC<MenuIconProps> = ({ isActive }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={containerCx({ isActive })}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0312 2.9375C14.1856 2.9375 13.5 3.62306 13.5 4.46875C13.5 5.31444 14.1856 6 15.0312 6C15.8769 6 16.5625 5.31444 16.5625 4.46875C16.5625 3.62306 15.8769 2.9375 15.0312 2.9375ZM12.5 4.46875C12.5 3.07078 13.6333 1.9375 15.0312 1.9375C16.4292 1.9375 17.5625 3.07078 17.5625 4.46875C17.5625 5.86672 16.4292 7 15.0312 7C13.6333 7 12.5 5.86672 12.5 4.46875Z"
      fill="url(#paint0_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5312 11.0625C20.6856 11.0625 20 11.7481 20 12.5938C20 13.4394 20.6856 14.125 21.5312 14.125C22.3769 14.125 23.0625 13.4394 23.0625 12.5938C23.0625 11.7481 22.3769 11.0625 21.5312 11.0625ZM19 12.5938C19 11.1958 20.1333 10.0625 21.5312 10.0625C22.9292 10.0625 24.0625 11.1958 24.0625 12.5938C24.0625 13.9917 22.9292 15.125 21.5312 15.125C20.1333 15.125 19 13.9917 19 12.5938Z"
      fill="url(#paint1_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8438 20C14.9981 20 14.3125 20.6856 14.3125 21.5312C14.3125 22.3769 14.9981 23.0625 15.8438 23.0625C16.6894 23.0625 17.375 22.3769 17.375 21.5312C17.375 20.6856 16.6894 20 15.8438 20ZM13.3125 21.5312C13.3125 20.1333 14.4458 19 15.8438 19C17.2417 19 18.375 20.1333 18.375 21.5312C18.375 22.9292 17.2417 24.0625 15.8438 24.0625C14.4458 24.0625 13.3125 22.9292 13.3125 21.5312Z"
      fill="url(#paint2_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6875 17.1562C4.84181 17.1562 4.15625 17.8418 4.15625 18.6875C4.15625 19.5332 4.84181 20.2188 5.6875 20.2188C6.53319 20.2188 7.21875 19.5332 7.21875 18.6875C7.21875 17.8418 6.53319 17.1562 5.6875 17.1562ZM3.15625 18.6875C3.15625 17.2895 4.28953 16.1562 5.6875 16.1562C7.08547 16.1562 8.21875 17.2895 8.21875 18.6875C8.21875 20.0855 7.08547 21.2188 5.6875 21.2188C4.28953 21.2188 3.15625 20.0855 3.15625 18.6875Z"
      fill="url(#paint3_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28125 7C4.43556 7 3.75 7.68556 3.75 8.53125C3.75 9.37694 4.43556 10.0625 5.28125 10.0625C6.12694 10.0625 6.8125 9.37694 6.8125 8.53125C6.8125 7.68556 6.12694 7 5.28125 7ZM2.75 8.53125C2.75 7.13328 3.88328 6 5.28125 6C6.67922 6 7.8125 7.13328 7.8125 8.53125C7.8125 9.92922 6.67922 11.0625 5.28125 11.0625C3.88328 11.0625 2.75 9.92922 2.75 8.53125Z"
      fill="url(#paint4_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7089 13.8863C20.9419 14.0346 21.0106 14.3436 20.8623 14.5766L17.3563 20.0853C17.2081 20.3183 16.899 20.387 16.6661 20.2387C16.4331 20.0904 16.3644 19.7814 16.5127 19.5484L20.0187 14.0397C20.1669 13.8067 20.476 13.738 20.7089 13.8863Z"
      fill="url(#paint5_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9874 5.66477C16.203 5.49223 16.5176 5.52714 16.6902 5.74275L20.6531 10.6949C20.8257 10.9105 20.7907 11.2252 20.5751 11.3977C20.3595 11.5703 20.0449 11.5354 19.8723 11.3197L15.9094 6.36756C15.7368 6.15196 15.7718 5.83731 15.9874 5.66477Z"
      fill="url(#paint6_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6169 5.05738C13.7231 5.31226 13.6027 5.60502 13.3478 5.71127L7.34949 8.21174C7.09461 8.31799 6.80186 8.1975 6.6956 7.94262C6.58935 7.68774 6.70984 7.39498 6.96472 7.28873L12.963 4.78826C13.2179 4.68201 13.5106 4.8025 13.6169 5.05738Z"
      fill="url(#paint7_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0015 12.6664C20.0146 12.9423 19.8017 13.1765 19.5258 13.1897L13.0238 13.4994C12.748 13.5126 12.5137 13.2996 12.5006 13.0238C12.4874 12.748 12.7004 12.5137 12.9762 12.5006L19.4782 12.1908C19.7541 12.1777 19.9883 12.3906 20.0015 12.6664Z"
      fill="url(#paint8_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6768 5.95876C14.9455 6.02273 15.1114 6.29236 15.0474 6.56099L13.4864 13.1158C13.4224 13.3845 13.1528 13.5504 12.8842 13.4864C12.6155 13.4224 12.4496 13.1528 12.5136 12.8842L14.0746 6.32932C14.1386 6.06069 14.4082 5.89478 14.6768 5.95876Z"
      fill="url(#paint9_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.60659 9.29838C6.74495 9.0594 7.05085 8.97784 7.28982 9.1162L13.2505 12.5673C13.4895 12.7057 13.5711 13.0115 13.4327 13.2505C13.2943 13.4895 12.9885 13.5711 12.7495 13.4327L6.78877 9.98161C6.54979 9.84325 6.46823 9.53736 6.60659 9.29838Z"
      fill="url(#paint10_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8419 12.5257C13.1039 12.4383 13.387 12.5799 13.4743 12.8419L15.6752 19.4455C15.7625 19.7075 15.6209 19.9906 15.359 20.0779C15.097 20.1653 14.8138 20.0237 14.7265 19.7617L12.5257 13.1581C12.4383 12.8961 12.5799 12.613 12.8419 12.5257Z"
      fill="url(#paint11_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16212 19.1001C7.2366 18.8342 7.51254 18.679 7.77845 18.7535L14.0225 20.5024C14.2884 20.5768 14.4436 20.8528 14.3691 21.1187C14.2946 21.3846 14.0187 21.5398 13.7528 21.4653L7.50874 19.7164C7.24283 19.6419 7.08764 19.366 7.16212 19.1001Z"
      fill="url(#paint12_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3947 12.693C13.5642 12.911 13.5249 13.2251 13.307 13.3947L7.59815 17.835C7.38018 18.0045 7.06604 17.9653 6.8965 17.7473C6.72696 17.5293 6.76623 17.2152 6.9842 17.0456L12.693 12.6053C12.911 12.4358 13.2251 12.4751 13.3947 12.693Z"
      fill="url(#paint13_linear_3957_3001)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.34253 10.0609C5.61845 10.0498 5.85107 10.2646 5.8621 10.5405L6.10585 16.6383C6.11688 16.9142 5.90214 17.1469 5.62622 17.1579C5.3503 17.1689 5.11768 16.9542 5.10665 16.6783L4.8629 10.5804C4.85187 10.3045 5.06661 10.0719 5.34253 10.0609Z"
      fill="url(#paint14_linear_3957_3001)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_3957_3001"
        x1="6.12881"
        y1="0.616605"
        x2="33.5755"
        y2="29.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C5CF3" />
        <stop offset="1" stopColor="#07358D" />
      </linearGradient>
    </defs>
  </svg>
)
