import { formatInTimeZone } from 'date-fns-tz'

/**
 * @returns A string representing the current JP absolute date in the format yyy/MM/dd.
 */
export const getCurrentJpDay = () => formatInTimeZone(new Date(), 'Asia/Tokyo', 'yyy/MM/dd')
/**
 * @returns A local date object representing the current JP absolute date, starting at 00:00:00 local time.
 * BEWARE: This is not the same as the start of the day in Japan, which is 00:00:00 JST (UTC+9).
 *
 */
export const getCurrentJpDayStart = () => new Date(new Date(getCurrentJpDay()).setHours(0, 0, 0))

/**
 * @returns A local date object representing the current JP absolute date, starting at 23:59:59 local time.
 *  * BEWARE: This is not the same as the end of the day in Japan, which is 23:59:59 JST (UTC+9).
 */
export const getCurrentJpDayEnd = () => new Date(new Date(getCurrentJpDay()).setHours(23, 59, 59))
