import clsx from 'clsx'
import { FC } from 'react'
import { ModalOverlay as AriaModalOverlay, ModalOverlayProps } from 'react-aria-components'
import {} from '../../constants'
import { elementIsNotInDialogWhitelist } from '../../utils'
import { overlayCx } from './Modal.css'

export const ModalOverlay: FC<ModalOverlayProps> = ({ children, className, ...props }) => (
  <AriaModalOverlay
    {...props}
    shouldCloseOnInteractOutside={elementIsNotInDialogWhitelist}
    className={clsx([
      overlayCx({
        isEntering: props.isOpen,
        isExiting: !props.isOpen,
      }),
      className,
    ])}
  >
    {children}
  </AriaModalOverlay>
)
