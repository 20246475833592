import { Locale, Maybe } from 'typ'
import { isNil, isNotIncluded } from 'typeguards'

const pluralize = (count: number, unit: string) => {
  const pluralizedUnit = count === 1 ? unit : `${unit}s`
  return `${count} ${pluralizedUnit}`
}
const jpDict = {
  ago: '前',
  now: 'たった今',
  nowAbsolute: '今',
  getMinutes: (count: number) => `${count}分`,
  getHours: (count: number) => `${count}時間`,
  getDays: (count: number) => `${count}日`,
  getWeeks: (count: number) => `${count}週間`,
  getMonths: (count: number) => `${count}ヶ月`,
  getYears: (count: number) => `${count}年`,
}
const enDict = {
  ago: 'ago',
  now: 'just now',
  nowAbsolute: 'now',
  getMinutes: (count: number) => pluralize(count, 'minute'),
  getHours: (count: number) => pluralize(count, 'hour'),
  getDays: (count: number) => pluralize(count, 'day'),
  getWeeks: (count: number) => pluralize(count, 'week'),
  getMonths: (count: number) => pluralize(count, 'month'),
  getYears: (count: number) => pluralize(count, 'year'),
}
const dict = {
  en: enDict,
  ja: jpDict,
}

/**
 *
 * @param time A time span
 * @param t A translator function
 * @param locale The current locale
 * @param isAbsolute Determines the return format: time + ago if false, plain time if true
 * @returns A string displaying an elapsed time value
 */
const extractTimeString = (time: string, locale: Locale, isAbsolute: boolean) =>
  isAbsolute ? time : `${time}${locale === 'en' ? ' ' : ''}${dict[locale].ago}`

/**
 *
 * @param date A past date input
 * @param locale The current locale
 * @param now Defaults to Date.now(), a date to use as a comparison
 * @param isAbsolute Determines the return format: time + ago if false, plain time if true
 * @returns A string stating the elapsed time from now.
 */

export const getElapsedTime = (
  date: Maybe<string | number | Date>,
  locale: string,
  now = new Date().getTime(),
  isAbsolute: boolean = false
): string => {
  if (isNil(date)) return ''
  const safeLocale = isNotIncluded(locale, ['en', 'ja']) ? 'en' : locale
  const min = 60000
  const hour = 60 * min
  const day = 24 * hour
  const week = 7 * day
  const month = 30 * day
  const year = 365 * day
  const then = new Date(date).getTime()
  const elapsed = now - then

  switch (true) {
    case elapsed < min:
      return isAbsolute ? dict[safeLocale].nowAbsolute : dict[safeLocale].now
    case elapsed < hour:
      return extractTimeString(
        dict[safeLocale].getMinutes(Math.floor(elapsed / min)),
        safeLocale,
        isAbsolute
      )
    case elapsed < day:
      return extractTimeString(
        dict[safeLocale].getHours(Math.floor(elapsed / hour)),
        safeLocale,
        isAbsolute
      )
    case elapsed < week:
      return extractTimeString(
        dict[safeLocale].getDays(Math.floor(elapsed / day)),
        safeLocale,
        isAbsolute
      )
    case elapsed < month:
      return extractTimeString(
        dict[safeLocale].getWeeks(Math.floor(elapsed / week)),
        safeLocale,
        isAbsolute
      )
    case elapsed < year:
      return extractTimeString(
        dict[safeLocale].getMonths(Math.floor(elapsed / month)),
        safeLocale,
        isAbsolute
      )
    default:
      return extractTimeString(
        dict[safeLocale].getYears(Math.floor(elapsed / year)),
        safeLocale,
        isAbsolute
      )
  }
}
