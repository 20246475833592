import { JSXElementConstructor, ReactElement, ReactNode, cloneElement, forwardRef } from 'react'
import { mergeProps, useObjectRef, useTooltipTrigger } from 'react-aria'
import {
  TooltipTriggerProps as BaseTooltipTriggerProps,
  useTooltipTriggerState,
} from 'react-stately'
import { Tooltip, TooltipProps } from './Tooltip'

export interface TooltipTriggerProps
  extends BaseTooltipTriggerProps,
    Omit<TooltipProps, 'state' | 'triggerRef'> {
  children: ReactElement<any, string | JSXElementConstructor<any>>
  tooltipContent: ReactNode
  keepOpenOnPress?: boolean
}

export const TooltipTrigger = forwardRef<any, TooltipTriggerProps>(
  (
    {
      children,
      tooltipContent,
      delay = 200,
      defaultOpen,
      closeDelay = 0,
      isOpen,
      isDisabled,
      onOpenChange,
      keepOpenOnPress = false,
      trigger,
      ...props
    },
    forwardedRef
  ) => {
    const tooptipTriggerProps: BaseTooltipTriggerProps = {
      delay,
      closeDelay,
      isDisabled,
      defaultOpen,
      isOpen,
      onOpenChange,
      trigger,
    }
    const state = useTooltipTriggerState(tooptipTriggerProps)
    const triggerRef = useObjectRef(forwardedRef)

    const {
      triggerProps: { onKeyDown, onPointerDown, ...triggerProps },
      tooltipProps,
    } = useTooltipTrigger(tooptipTriggerProps, state, triggerRef)

    //Normally the tooltip closes on keydown, but we want to keep it open when the user is pressing the trigger
    const handleKeyDown: typeof onKeyDown = (e) => {
      if (!keepOpenOnPress) onKeyDown?.(e)
    }

    const handlePointerDown: typeof onPointerDown = (e) => {
      if (!keepOpenOnPress) onPointerDown?.(e)
    }

    const childProps = children.props
    const mergedChildrenProps = mergeProps(
      { onKeyDown: handleKeyDown, onPointerDown: handlePointerDown, ...triggerProps },
      childProps
    )
    return (
      <>
        {cloneElement(children as any, { ref: triggerRef, ...mergedChildrenProps })}

        <Tooltip state={state} triggerRef={triggerRef} {...mergeProps(tooltipProps, props)}>
          {tooltipContent}
        </Tooltip>
      </>
    )
  }
)

TooltipTrigger.displayName = 'TooltipTrigger'
