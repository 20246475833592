import { LoaderProps } from '../../loader'
import { Size } from '../Button.css'

export const getButtonIconSize = (size: Size): LoaderProps['size'] => {
  switch (size) {
    case 'lg':
      return 'md'
    case 'sm':
      return 'xs'
    case 'md':
    default:
      return 'sm'
  }
}
