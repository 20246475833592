import { startOfDay } from 'date-fns'
import { offsetLocalTimeToJstZeroString } from './offset-local-time-to-jst-zero-string'

export function secondsToJpStartOfDay(timestamp: number): { ms: number; seconds: number } {
  const date = new Date(timestamp * 1000)

  const startOfDayInJST = new Date(offsetLocalTimeToJstZeroString(startOfDay(date)))
  const time = startOfDayInJST.getTime()
  return {
    ms: time,
    seconds: time / 1000,
  }
}
