import { getTimezoneOffset } from 'date-fns-tz'
import { toBrowserSafeUTCString } from './to-utc-string'

const JP_OFFSET = 32400000
/**
 * This function returns the result of subtracting the timezone offset between local time (override if applied) and Japan time (UTC+9)
 *
 * For example if the local time 21:00 UTC, then this function will return 21:00 in JST (UTC+9)
 *
 * It is currently used in charts where we use local time for the filter controls but need to transform
 * it to the same exact clock time in JST for the API to consume it.
 *
 * @param time A time value expressed in the user's local time
 * @param timezoneOverride ONLY used for testing: it allows attempting to override the `time` value with user-specified offsets
 * @returns An ISO string in the format 2000-01-01T00:00:00Z
 */
export const offsetLocalTimeToJstString = (time: Date | string, timezoneOverride?: string) => {
  const currentTz = timezoneOverride ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  const offset = getTimezoneOffset(currentTz, new Date(time))
  return toBrowserSafeUTCString(new Date(new Date(time).getTime() + offset - JP_OFFSET))
}
